.custom-input input {
  font-family: 'Montserrat', sans-serif !important;
}

.custom-input-area {
  font-family: 'Montserrat', sans-serif !important;
}

.ant-form-item-explain-error {
  font-family: 'Montserrat', sans-serif !important;
}

.custom-check-box .MuiTypography-root{
  font-family: 'Montserrat', sans-serif !important;
}
