@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f5f5f5;
  font-family: Calibri, sans-serif;
}

.custom-select fieldset {
  border: none !important;
}
.even-row {
  background-color: #f2f2f2 !important; 
}

.odd-row {
  background-color: #ffffff !important; 
}

 .sticky-search-bar {
  position: fixed;
  width: 100%;
  z-index: 1000; 
}